let gExpertInfo = {
  name: '黃惠瑩',
  license: '獸師執85024',
  unitName: '恩孺動物診所',
  unitLicense: '獸業284',
  unitAddress: '100台北市中正區金山南路一段92號1樓',
  sex: '女',
  email: 'silverboxjenny@gmailcom',
  phone: '0982573676',
  birthday: '1985-07-23',
};

export { gExpertInfo };
