import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import MD5 from 'react-native-md5';

function Register() {
  let navigate = useNavigate();
  var isMember = true;
  var sex = '男';
  function memberForm() {
    isMember = true;
  }
  function expertForm() {
    isMember = false;
  }
  function setSex(sex) {
    sex = sex;
  }
  function sendRequest() {
    if (isMember) {
      const data = {
        username: document.getElementById('username').value,
        password: MD5.hex_md5(document.getElementById('password').value),
        name: document.getElementById('name').value,
        email: document.getElementById('email').value,
        phone: document.getElementById('phone').value,
        sex: sex,
        birthday: document.getElementById('birthday').value,
      };
      axios
        .post(process.env.REACT_APP_URL + '/v1/user/info/create', data)
        .then(function (response) {
          console.log(response.data.data);
          navigate('/login');
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
    } else {
      const expertdata = {
        username: document.getElementById('username').value,
        password: MD5.hex_md5(document.getElementById('password').value),
        name: document.getElementById('name').value,
        email: document.getElementById('email').value,
        phone: document.getElementById('phone').value,
        sex: sex,
        birthday: document.getElementById('birthday').value,
        unit_name: document.getElementById('unit_name').value,
        unit_license: document.getElementById('unit_license').value,
        unit_address: document.getElementById('unit_address').value,
      };
      axios
        .post(process.env.REACT_APP_URL + '/v1/expert/info/create', expertdata)
        .then(function (response) {
          console.log(response.data.data);
          navigate('/login');
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
    }
  }
  return (
    <div class='FloatWin'>
      <div class='FormBox CircleDiA'>
        <div class='HeadlineA'>
          <h3>註冊會員</h3>
          <div class='menu'>
            <Link to='/login'>會員登入</Link>
            <Link to='/forget'>忘記密碼</Link>
          </div>
        </div>

        <ul class='FillIn'>
          <li>
            <label>身份</label>
            <div class='box'>
              <label>
                <input type='radio' name='identity' value='飼主' id='owner' checked onClick={memberForm} />
                <span>飼主</span>
              </label>
              <label>
                <input type='radio' name='identity' value='獸醫' id='veterinary' onClick={expertForm} />
                <span>獸醫</span>
              </label>
            </div>
          </li>
          <li>
            <label>帳號</label>
            <div class='box'>
              <input type='text' id='username' />
            </div>
          </li>
          <li>
            <label>密碼</label>
            <div class='box'>
              <input type='password' id='password' />
            </div>
          </li>
          <li>
            <label>再次輸入密碼</label>
            <div class='box'>
              <input type='password' />
            </div>
          </li>
          <li>
            <label>姓名</label>
            <div class='box'>
              <input type='text' id='name' />
            </div>
          </li>
          <li>
            <label>電子信箱</label>
            <div class='box'>
              <input type='email' id='email' />
            </div>
          </li>
          <li>
            <label>聯絡電話</label>
            <div class='box'>
              <input type='tel' id='phone' />
            </div>
          </li>
          <li>
            <label>性別</label>
            <div class='box'>
              <label>
                <input type='radio' name='PersonSex' value='男' id='male' onClick={setSex('男')} />
                <span>男</span>
              </label>
              <label>
                <input type='radio' name='PersonSex' value='女' id='Female' onClick={setSex('女')} />
                <span>女</span>
              </label>
              <label>
                <input type='radio' name='PersonSex' value='女' id='NotCategorized' onClick={setSex('不透漏')} />
                <span>不透漏</span>
              </label>
            </div>
          </li>
          <li>
            <label>年齡區間</label>
            <div class='box'>
              <select id='birthday'>
                <option>20歲以下</option>
                <option>21歲~30歲</option>
                <option>31歲~40歲</option>
                <option>41歲~50歲</option>
                <option>51歲以上</option>
              </select>
            </div>
          </li>

          <li>
            <label>服務單位(獸醫須填)</label>
            <div class='box'>
              <input type='text' id='unit_name' />
            </div>
          </li>
          <li>
            <label>職稱(獸醫須填)</label>
            <div class='box'>
              <input type='text' id='unit_license' />
            </div>
          </li>
          <li>
            <label>服務單位地址(獸醫須填)</label>
            <div class='box'>
              <input type='text' id='unit_address' />
            </div>
          </li>
        </ul>
        <div class='button'>
          <input type='submit' value='確認' onClick={sendRequest} />
          <Link to='/'>取消</Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
