import { Link } from 'react-router-dom';

function Forget() {
  return (
    <div class='FloatWin'>
      <div class='FormBox CircleDiA'>
        <div class='HeadlineA'>
          <h3>忘記密碼</h3>
          <div class='menu'>
            <Link to='/register'>註冊會員</Link>
            <Link to='/login'>會員登入</Link>
          </div>
        </div>
        <ul class='FillIn'>
          <li>
            <label>帳號</label>
            <div class='box'>
              <input type='text' />
            </div>
          </li>
          <li>
            <label>電子信箱</label>
            <div class='box'>
              <input type='email' />
            </div>
            <div class='ps'>請至您原先註冊的E-MAIL收取信件</div>
          </li>
        </ul>
        <div class='button'>
          <input type='submit' value='登入' />
          <Link to='/'>取消</Link>
        </div>
      </div>
    </div>
  );
}

export default Forget;
