import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
//import Context from '../store/context';

function PetEdit() {
  const { id } = useParams();
  //const [value, setValue] = useContext(Context);
  const [sex, setSex] = useState('');
  const [gettype, setGetType] = useState('');
  const [birthcontrol, setBirthControl] = useState('');
  //const [img, setImg] = useState('');
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [displayfile, setDisplayFile] = useState('');
  const [file, setFile] = useState();
  let navigate = useNavigate();
  function setVisibleTrue() {
    setVisible(true);
  }
  function setVisibleFalse() {
    setVisible(false);
  }
  /* function batchFillin() {
    navigate('/petbatchfillin');
  } */
  /* function fillin() {
    navigate('/petfillin');
  } */
  function toPetList() {
    navigate('/memberinfo');
  }
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  function handleNameChange(event){
    setData({
      id: data.id,
      user_id: data.user_id,
      name: event.target.value,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: data.type,
      color: data.color,
      sex: data.sex,
      feature: data.feature,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleChipChange(event) {
   setData({
     id: data.id,
     user_id: data.user_id,
     name: data.name,
     chip_no: event.target.value,
     blood_type: data.blood_type,
     species: data.species,
     type: data.type,
     color: data.color,
     sex: data.sex,
     feature: data.feature,
     personality: data.personality,
     birthday: data.birthday,
     age: data.age,
     get_type: data.get_type,
     get_date: data.get_date,
     birth_control: data.birth_control,
     birth_control_date: data.birth_control_date,
     photo: data.photo,
   });
  }
  function handleBloodChange(event) {
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: event.target.value,
      species: data.species,
      type: data.type,
      color: data.color,
      sex: data.sex,
      feature: data.feature,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleTypeChange(event) {
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: event.target.value,
      color: data.color,
      sex: data.sex,
      feature: data.feature,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleColorChange(event) {
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: data.type,
      color: event.target.value,
      sex: data.sex,
      feature: data.feature,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleFeatureChange(event) {
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: data.type,
      color: data.color,
      sex: data.sex,
      feature: event.target.value,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handlePersonalityChange(event) {
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: data.type,
      color: data.color,
      sex: data.sex,
      feature: data.feature,
      personality: event.target.value,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleSexChange(event) {
    setSex(event.target.value);
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: data.type,
      color: data.color,
      sex: event.target.value,
      feature: data.feature,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleGetTypeChange(event) {
    setGetType(event.target.value);
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: data.type,
      color: data.color,
      sex: data.sex,
      feature: data.feature,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: event.target.value,
      get_date: data.get_date,
      birth_control: data.birth_control,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleBirthControlChange(event) {
    setBirthControl(event.target.value);
    setData({
      id: data.id,
      user_id: data.user_id,
      name: data.name,
      chip_no: data.chip_no,
      blood_type: data.blood_type,
      species: data.species,
      type: data.type,
      color: data.color,
      sex: data.sex,
      feature: data.feature,
      personality: data.personality,
      birthday: data.birthday,
      age: data.age,
      get_type: data.get_type,
      get_date: data.get_date,
      birth_control: event.target.value,
      birth_control_date: data.birth_control_date,
      photo: data.photo,
    });
  }
  function handleUpdate(){
    const url = process.env.REACT_APP_URL + '/v1/pet/info/update';
    const token = sessionStorage.getItem('token');

    const mydata = { id:data.id, name: data.name, chip_no: data.chip_no, blood_type: data.blood_type, sex: data.sex, get_type: data.get_type, birth_control: data.birth_control, type: data.type, color: data.color, feature: data.feature, personality: data.personality, photo: data.photo };

    //alert(JSON.stringify(mydata));

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    };
    axios
      .post(url, mydata, config)
      .then((response) => {
        console.log(response.data);
        alert('資料更新成功');
        navigate('/memberinfo');
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function handleSubmit(event) {
    event.preventDefault();
    const url = process.env.REACT_APP_URL + '/v1/pet/image/upload';
    const token = sessionStorage.getItem('token');
    const current = new Date();
    const formData = new FormData();

    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot + 1);

    setDisplayFile('(' + file.name + ')');

    formData.append('file', file);
    formData.append('fileName', 'petimg-' + `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}-${current.getHours()}-${current.getMinutes()}-${current.getSeconds()}` + '.' + ext);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        //setImg(response.data.url);
        //setData({ photo: response.data.url });
        setData({
          id: data.id,
          user_id: data.user_id,
          name: data.name,
          chip_no: data.chip_no,
          blood_type: data.blood_type,
          species: data.species,
          type: data.type,
          color: data.color,
          sex: data.sex,
          feature: data.feature,
          personality: data.personality,
          birthday: data.birthday,
          age: data.age,
          get_type: data.get_type,
          get_date: data.get_date,
          birth_control: data.birth_control,
          birth_control_date: data.birth_control_date,
          photo: response.data.url,
        });
        alert('上傳資料成功');
        setVisibleFalse();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    const api = process.env.REACT_APP_URL + '/v1/pet/info?id=' + id;
    const token = sessionStorage.getItem('token');
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(api, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(response.data);
        setData({
          id: response.data.id,
          user_id: response.data.user_id,
          name: response.data.name,
          chip_no: response.data.chip_no,
          blood_type: response.data.blood_type,
          species: response.data.species,
          type: response.data.type,
          color: response.data.color,
          sex: response.data.sex,
          feature: response.data.feature,
          personality: response.data.personality,
          birthday: response.data.birthday,
          age: response.data.age,
          get_type: response.data.get_type,
          get_date: response.data.get_date,
          birth_control: response.data.birth_control,
          birth_control_date: response.data.birth_control_date,
          photo: response.data.photo,
        });
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      {visible && (
        <div class='FloatWin'>
          <div class='FormBox CircleDiA'>
            <div class='HeadlineA'>
              <h3>上傳檔案</h3>
            </div>
            <ul class='FillIn'>
              <li>
                <form onSubmit={handleSubmit}>
                  <input name='file' type='file' accept='image/png, image/jpeg' onChange={handleChange} />
                  <br />
                  <br />
                  <input type='submit' value='上傳' style={{ height: '30px' }} />
                  <input type='submit' onClick={setVisibleFalse} value='取消' style={{ height: '30px' }} />
                </form>
              </li>
            </ul>
          </div>
        </div>
      )}

      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>編輯寵物資訊</h3>
            {/* <div class='button'>
              <Link to='/petinfo'>編輯儲存</Link>
            </div> */}
          </div>
          <ul class='FillIn'>
            <li>
              <label>照片 {displayfile}</label>
              <div class='box'>
                <input type='button' value='更換照片' onClick={setVisibleTrue} />
              </div>
            </li>
            <li>
              <label>寵物名</label>
              <div class='box'>
                <input type='text' value={data.name} onChange={handleNameChange} />
              </div>
            </li>
            <li>
              <label>晶片號碼(非必填)</label>
              <div class='box'>
                <input type='number' value={data.chip_no} onChange={handleChipChange} />
              </div>
            </li>
            {/* <li>
              <label>物種</label>
              <div class='box'>
                <select>
                  <option>貓</option>
                  <option>狗</option>
                </select>
                <input type='text' placeholder='其他寶貝' />
              </div>
            </li> */}
            <li>
              <label>血型(非必填)</label>
              <div class='box'>
                {/* <select>
                  <option>DEA1.1陽性</option>
                  <option>DEA1.2陽性</option>
                  <option>DEA1.1陰性</option>
                  <option>DEA1.2陰性</option>
                  <option>DEA3</option>
                  <option>DEA4</option>
                  <option>DEA5</option>
                  <option>DEA6</option>
                  <option>DEA7</option>
                  <option>DEA8</option>
                  <option>DEA9</option>
                  <option>DEA10</option>
                  <option>DEA11</option>
                  <option>DEA12</option>
                  <option>DEA13</option>
                </select>
                <select>
                  <option>A</option>
                  <option>B</option>
                  <option>AB</option>
                </select> */}
                <input type='text' value={data.blood_type} onChange={handleBloodChange} />
              </div>
            </li>
            <li>
              <label>品種</label>
              <div class='box'>
                <input type='text' value={data.type} onChange={handleTypeChange} />
              </div>
            </li>
            <li>
              <label>毛色</label>
              <div class='box'>
                <input type='text' value={data.color} onChange={handleColorChange} />
              </div>
            </li>
            <li>
              <label>生理性別</label>
              <div class='box'>
                <label>
                  <input type='radio' name='PersonSex' value='男' id='male' checked={sex === '男'} onChange={handleSexChange} />
                  <span>男</span>
                </label>
                <label>
                  <input type='radio' name='PersonSex' value='女' id='Female' checked={sex === '女'} onChange={handleSexChange} />
                  <span>女</span>
                </label>
              </div>
            </li>
            <li>
              <label>特徵</label>
              <div class='box'>
                <input type='text' value={data.feature} onChange={handleFeatureChange} />
              </div>
            </li>
            <li>
              <label>個性</label>
              <div class='box'>
                <input type='text' value={data.personality} onChange={handlePersonalityChange} />
              </div>
            </li>
            {/* <li>
              <label>生日</label>
              <div class='box'>
                <input type='date' />
              </div>
            </li> */}
            <li>
              <label>獲得方式</label>
              <div class='box'>
                <label>
                  <input type='radio' name='BuyAdoption' value='購買' id='buy' checked={gettype === '購買'} onChange={handleGetTypeChange} />
                  <span>購買</span>
                </label>
                <label>
                  <input type='radio' name='BuyAdoption' value='領養' id='adoption' checked={gettype === '領養'} onChange={handleGetTypeChange} />
                  <span>領養</span>
                </label>
                {/* <input type='date' /> */}
              </div>
            </li>
            <li>
              <label>是否節育</label>
              <div class='box'>
                <label>
                  <input type='radio' name='Sterilization' value='是' id='SterilizationY' checked={birthcontrol === '是'} onChange={handleBirthControlChange} />
                  <span>是</span>
                </label>
                {/* <input type='date' /> */}
                <label>
                  <input type='radio' name='Sterilization' value='否' id='SterilizationN' checked={birthcontrol === '否'} onChange={handleBirthControlChange} />
                  <span>否</span>
                </label>
              </div>
            </li>
          </ul>
        </div>

        <div class='content'>
          <div class='AddPetInfo button'>
            <input type='submit' value='取消寵物編輯' onClick={toPetList} />
            <input type='submit' value='寵物編輯儲存' onClick={handleUpdate} />
            {/* <input type='submit' value='＋ 新增每日詳細資訊' onClick={fillin} />
            <input type='submit' value='批次上傳 Excel、CSV檔案 ' /> */}
          </div>
          {/* <ul class='PetDailyLogLi'>
            <li>
              <div class='day'>2022/04/12週二</div>
              <a href='#' class='DailyLog'>
                <span class='time'>11:03</span>
                <span class='weight'>
                  體重<b>11</b>
                  <small>kg</small>
                </span>
                <span class='up'>▲</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li>
            <li>
              <div class='day'>2022/04/11週一</div>
              <a href='#' class='DailyLog'>
                <span class='time'>11:03</span>
                <span class='weight'>
                  體重<b>10.5</b>
                  <small>kg</small>
                </span>
                <span class='equal'>=</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li>
            <li>
              <div class='day'>2022/04/10週日</div>
              <a href='#' class='DailyLog'>
                <span class='time'>11:03</span>
                <span class='weight'>
                  體重<b>10.5</b>
                  <small>kg</small>
                </span>
                <span class='down'>▼</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li>
          </ul> */}
        </div>
      </section>
    </div>
  );
}

export default PetEdit;
