import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Context from '../store/context';
import { gExpertInfo } from '../data/expertData';

function ExpertInfo() {
  const [value, setValue] = useContext(Context);
  const [data, setData] = useState({
    name: '黃惠瑩',
    license: '獸師執85024',
    unitName: '恩孺動物診所',
    unitLicense: '獸業284',
    unitAddress: '100台北市中正區金山南路一段92號1樓',
    sex: '女',
    email: 'silverboxjenny@gmailcom',
    phone: '0982573676',
    birthday: '1985-07-23'
  });

  useEffect(() => {
    const api = process.env.REACT_APP_URL + '/v1/expert/info';
    const token = sessionStorage.getItem('token');
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(api, {
          headers: {
            Authorization: 'Bearer '+token,
          },
        });
        console.log(response.data);
        setData({
          name: response.data.name,
          license: response.data.license_id,
          unitName: response.data.unit_name,
          unitLicense: response.data.unit_license,
          unitAddress: response.data.unit_address,
          sex: response.data.sex,
          email: response.data.email,
          phone: response.data.phone,
          birthday: response.data.birthday
        });
        gExpertInfo.name=response.data.name;
        gExpertInfo.license=response.data.license_id;
        gExpertInfo.unitName=response.data.unit_name;
        gExpertInfo.unitLicense=response.data.unit_license;
        gExpertInfo.unitAddress=response.data.unit_address;
        gExpertInfo.sex=response.data.sex;
        gExpertInfo.email=response.data.email;
        gExpertInfo.phone=response.data.phone;
        gExpertInfo.birthday=response.data.birthday;
        setValue({login: value.login,name:response.data.name,expert: value.expert } );
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>專家資訊</h3>
            <div class='button'>
              <Link to='/expertedit'>修改內容</Link>
            </div>
          </div>
          <ul class='FillIn'>
            <li>
              <label>姓名</label>
              <div class='AnswerBox'>{data.name}</div>
            </li>
            {/* <li>
              <label>執業執照號碼</label>
              <div class='AnswerBox'>{data.license}</div>
            </li> */}
            <li>
              <label>服務單位</label>
              <div class='AnswerBox'>{data.unitName}</div>
            </li>
            <li>
              <label>職稱</label>
              <div class='AnswerBox'>{data.unitLicense}</div>
            </li>
            <li>
              <label>服務單位地址</label>
              <div class='AnswerBox'>{data.unitAddress}</div>
            </li>
            <li>
              <label>性別</label>
              <div class='AnswerBox'>{data.sex}</div>
            </li>
            {/* <li>
              <label>電子信箱</label>
              <div class='AnswerBox'>{data.email}</div>
            </li> */}
            <li>
              <label>聯絡電話</label>
              <div class='AnswerBox'>{data.phone}</div>
            </li>
            <li>
              <label>年齡區間</label>
              <div class='AnswerBox'>{data.birthday}</div>
            </li>
          </ul>
        </div>

        <div class='content'>
          <ul class='PetLi'>
            <li>
              <div class='photo'>
                <img src='_img/pet/CatA.jpg' alt='寵物照片' />
              </div>
              <div class='name'>小黑(3歲)</div>
              <ul class='menu'>
                <li>
                  <Link to='/petinfo'>
                    <i class='material-icons'>visibility</i>
                  </Link>
                </li>
                <li>
                  <Link to='/petedit'>
                    <i class='material-icons'>edit</i>
                  </Link>
                </li>
                <li>
                  <Link to='/share'>
                    <i class='material-icons'>share</i>
                  </Link>
                </li>
                <li>
                  <Link to='/delete'>
                    <i class='material-icons'>delete</i>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div class='AddPetInfo button'>
            <input type='submit' value='＋ 新增寵物' />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ExpertInfo;
