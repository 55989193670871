import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Context from '../store/context';
import React, { useEffect, useState, useContext } from 'react';

function PetCreate() {
  let navigate = useNavigate();
  const [value, setValue] = useContext(Context);
  const [img, setImg] = useState('');
  const [displayfile, setDisplayFile] = useState('');
  const [name, setName] = useState('');
  const [chip, setChip] = useState('');
  const [sex, setSex] = useState('');
  const [blood, setBlood] = useState('');
  const [type, setType] = useState('');
  const [gettype, setGetType] = useState('');
  const [birthcontrol, setBirthControl] = useState('');
  const [color, setColor] = useState('');
  const [feature, setFeature] = useState('');
  const [personality, setPersonality] = useState('');
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState();
  function toPetList() {
    navigate('/memberinfo');
  }
  function setVisibleTrue(){
    setVisible(true);
  }
  function setVisibleFalse() {
    setVisible(false);
  }
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  function handleSexChange(event) {
    setSex(event.target.value);
  }
  function handleGetTypeChange(event){
    setGetType(event.target.value);
  }
  function handleBirthControlChange(event) {
    setBirthControl(event.target.value);
  }
  function handleSubmit(event) {
    event.preventDefault();
    const url = process.env.REACT_APP_URL + '/v1/pet/image/upload';
    const token = sessionStorage.getItem('token');
    const current = new Date();
    const formData = new FormData();

    const lastDot = file.name.lastIndexOf('.');
    const ext = file.name.substring(lastDot + 1);

    setDisplayFile('('+file.name+')');

    formData.append('file', file);
    formData.append('fileName', 'petimg-' + `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}-${current.getHours()}-${current.getMinutes()}-${current.getSeconds()}` + '.' + ext);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token,
      },
    };
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        setImg(response.data.url);
        alert('上傳資料成功');
        setVisibleFalse();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function doPetCreate() {
    const data = { name: name, chip_no: chip, blood_type: blood, sex: sex, get_type: gettype, birth_control: birthcontrol,type: type, color: color, feature: feature, personality: personality, photo: img };
    const token = sessionStorage.getItem('token');
    const headers = { Authorization: 'Bearer ' + token };
    axios
      .post(process.env.REACT_APP_URL + '/v1/pet/info/create', data, {
        headers: headers,
      })
      .then(function (response) {
        alert("寵物新增成功！");
        console.log(response.data.data);
        navigate('/memberinfo');
      })
      .catch(function (error) {
        alert('寵物新增失敗！');
        console.log(error);
      });
  }
  return (
    <div>
      {visible && (
        <div class='FloatWin'>
          <div class='FormBox CircleDiA'>
            <div class='HeadlineA'>
              <h3>上傳檔案</h3>
            </div>
            <ul class='FillIn'>
              <li>
                <form onSubmit={handleSubmit}>
                  <input name='file' type='file' accept='image/png, image/jpeg' onChange={handleChange} />
                  <br />
                  <br />
                  <input type='submit' value='上傳' style={{ height: '30px' }} />
                  <input type='submit' onClick={setVisibleFalse} value='取消' style={{ height: '30px' }} />
                </form>
              </li>
            </ul>
          </div>
        </div>
      )}

      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>新增寵物資訊</h3>
            {/* <div class='button' onClick={doPetCreate}>
              <a>確認新增</a>
            </div> */}
          </div>
          <ul class='FillIn'>
            <li>
              <label>照片 {displayfile}</label>
              <div class='box'>
                <input type='button' value='新增' onClick={setVisibleTrue} />
              </div>
            </li>
            <li>
              <label>寵物名</label>
              <div class='box'>
                <input type='text' onChange={(event) => setName(event.target.value)} />
              </div>
            </li>
            <li>
              <label>晶片號碼(非必填)</label>
              <div class='box'>
                <input type='number' onChange={(event) => setChip(event.target.value)} />
              </div>
            </li>
            {/* <li>
              <label>物種</label>
              <div class='box'>
                <select>
                  <option>貓</option>
                  <option>狗</option>
                </select>
                <input type='text' placeholder='其他寶貝' />
              </div>
            </li> */}
            <li>
              <label>血型(非必填)</label>
              <div class='box'>
                <input type='text' onChange={(event) => setBlood(event.target.value)} />
                {/* <select>
                  <option>DEA1.1陽性</option>
                  <option>DEA1.2陽性</option>
                  <option>DEA1.1陰性</option>
                  <option>DEA1.2陰性</option>
                  <option>DEA3</option>
                  <option>DEA4</option>
                  <option>DEA5</option>
                  <option>DEA6</option>
                  <option>DEA7</option>
                  <option>DEA8</option>
                  <option>DEA9</option>
                  <option>DEA10</option>
                  <option>DEA11</option>
                  <option>DEA12</option>
                  <option>DEA13</option>
                </select>
                <select>
                  <option>A</option>
                  <option>B</option>
                  <option>AB</option>
                </select>
                <input type='text' placeholder='其他' /> */}
              </div>
            </li>
            <li>
              <label>品種</label>
              <div class='box'>
                <input type='text' onChange={(event) => setType(event.target.value)} />
              </div>
            </li>
            <li>
              <label>毛色</label>
              <div class='box'>
                <input type='text' onChange={(event) => setColor(event.target.value)} />
              </div>
            </li>
            <li>
              <label>生理性別</label>
              <div class='box' onChange={handleSexChange}>
                <label>
                  <input type='radio' name='PersonSex' value='男' id='male' />
                  <span>男</span>
                </label>
                <label>
                  <input type='radio' name='PersonSex' value='女' id='Female' />
                  <span>女</span>
                </label>
              </div>
            </li>
            <li>
              <label>特徵</label>
              <div class='box'>
                <input type='text' onChange={(event) => setFeature(event.target.value)} />
              </div>
            </li>
            <li>
              <label>個性</label>
              <div class='box'>
                <input type='text' onChange={(event) => setPersonality(event.target.value)} />
              </div>
            </li>
            {/*  <li>
              <label>生日</label>
              <div class='box'>
                <input type='date' />
              </div>
            </li> */}
            <li>
              <label>獲得方式</label>
              <div class='box' onChange={handleGetTypeChange}>
                <label>
                  <input type='radio' name='BuyAdoption' value='購買' id='buy' />
                  <span>購買</span>
                </label>
                <label>
                  <input type='radio' name='BuyAdoption' value='領養' id='adoption' />
                  <span>領養</span>
                </label>
                {/* <input type='date' /> */}
              </div>
            </li>
            <li>
              <label>是否節育</label>
              <div class='box' onChange={handleBirthControlChange}>
                <label>
                  <input type='radio' name='Sterilization' value='是' id='SterilizationY' />
                  <span>是</span>
                </label>
                <label>
                  <input type='radio' name='Sterilization' value='否' id='SterilizationN' />
                  <span>否</span>
                </label>
                {/* <input type='date' /> */}
              </div>
            </li>
          </ul>
        </div>

        <div class='content'>
          <div class='AddPetInfo button'>
            <input type='submit' value='取消新增寵物' onClick={toPetList} />
            <input type='submit' value='確認新增寵物' onClick={doPetCreate} />
          </div>
          {/* <ul class='PetDailyLogLi'>
            <li>
              <div class='day'>2022/04/12週二</div>
              <a href='#' class='DailyLog'>
                <span class='time'>11:03</span>
                <span class='weight'>
                  體重<b>11</b>
                  <small>kg</small>
                </span>
                <span class='up'>▲</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li>
            <li>
              <div class='day'>2022/04/11週一</div>
              <a href='#' class='DailyLog'>
                <span class='time'>11:03</span>
                <span class='weight'>
                  體重<b>10.5</b>
                  <small>kg</small>
                </span>
                <span class='equal'>=</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li>
            <li>
              <div class='day'>2022/04/10週日</div>
              <a href='#' class='DailyLog'>
                <span class='time'>11:03</span>
                <span class='weight'>
                  體重<b>10.5</b>
                  <small>kg</small>
                </span>
                <span class='down'>▼</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li>
          </ul> */}
        </div>
      </section>
    </div>
  );
}

export default PetCreate;
