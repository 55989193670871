function Qna() {
  return (
    <div>
      <section class='DivideA'>
        <div class='FormBox CircleDiA QAPhotoA'>
          <div class='HeadlineA'>
            <h3>醫說毛小孩</h3>
          </div>
        </div>

        <div class='content OldQALi'>
          <div class='FormBox InfoLi'>
            <div class='HeadlineA'>
              <h3></h3>
              <div class='button'>
                <a href='#' class='now'>
                  貓
                </a>
                <a href='#'>狗</a>
              </div>
            </div>
            <div></div>
          </div>
          <div class='MedicalRecords'>
            <div class='HeadlineB'>
              <h5>貓咪幾歲要打疫苗？</h5>
            </div>
            <div class='txt'>在貓咪滿2個月以後，只要健康狀況良好，就可以帶去施打疫苗了。而幼貓首次接種疫苗時，需要每間隔1個月施打一次，而最後一次施打最好是在滿4月齡以後，效果會較佳。後續就跟一般成貓一樣，每年定期施打一次即可。</div>
            <div class='AddPetInfo button'>
              <input type='submit' value='該篇有醫生附註需登入後才能查看 登入 / 註冊' />
            </div>
            <div class='ATxt'>
              2022/04/06黃慧璧醫師附註
              <br />
              小狗的年齡是以出生幾年就是幾歲。也有人將小狗歲數換算成人的年齡，但標準未必相同，可能有出入。一般來說，小狗12個月也就是一歲，謂之成犬。
            </div>
            <div class='ATxt'>
              2022/05/26張倩倩醫師附註
              <br />
              一般來說，小狗的年齡是以出生幾年就是幾歲。也有人將小狗歲數換算成人的年齡，但標準未必相同，可能有出入。一般來說，小狗12個月也就是一歲，謂之成犬。
            </div>
          </div>
          <div class='MedicalRecords'>
            <div class='HeadlineB'>
              <h5>貓咪疫苗怎麼選擇？</h5>
            </div>
            <div class='txt'>
              疫苗要依需求選擇，不是項目越多越好，一般貓咪建議施打「三合一疫苗」即可。3合1疫苗可以幫助貓咪抵禦常見的：貓瘟、鼻支氣管炎、貓卡里西病毒..等疾病。但如果你的貓咪，平時會接觸流浪貓，或是生活環境有：「白血病」、「披衣菌肺炎」，就可以和醫師討論，是否施打「四合一疫苗」或是「五合一疫苗」。
            </div>
            <div class='AddPetInfo button'>
              <input type='submit' value='該篇有醫生附註需登入後才能查看 登入 / 註冊' />
            </div>
            <div class='ATxt'>
              2022/04/07黃慧璧醫師附註
              <br />
              建議先與您的獸醫師討論施打三合一疫苗的時間、確認先前施打疫苗時有無任何不適症狀。施打前獸醫師會詳細檢查毛小孩的身體狀況，評估目前是否有其他疾病影響等。施打後除了注意可能產生的疫苗反應，也建議一周內不特意洗澡，以免著涼、緊迫等影響免疫力的產生~
            </div>
            <div class='ATxt'>
              2022/05/26張倩倩醫師附註
              <br />
              建議先帶到動物醫院由醫師做基本健康檢查評估，一般會先使用驅蟲滴劑預防或治療可能的內/外寄生蟲疾病，待實際照顧2-4周之後，再評估施打貓三合一疫苗喔。
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Qna;
