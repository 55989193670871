import { Link, useNavigate } from 'react-router-dom';
import { gMemberInfo } from '../data/memberData';

function MemberEdit() {
  let navigate = useNavigate();
  function memberInfo() {
    navigate('/memberinfo');
  }
  return (
    <div>
      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>編輯飼主資訊</h3>
            <div class='button'>
              <Link to='/memberinfo'>內容存檔</Link>
            </div>
          </div>
          <ul class='FillIn'>
            <li>
              <label>姓名</label>
              <div class='box'>
                <input type='text' value={gMemberInfo.name} />
              </div>
            </li>
            {/*  <li>
              <label>飼主證件號碼(非必填)</label>
              <div class='box'>
                <input type='number' />
              </div>
            </li> */}
            <li>
              <label>性別</label>
              <div class='box'>
                <label>
                  <input type='radio' name='PersonSex' value='男' id='male' checked={gMemberInfo.sex === '男'} />
                  <span>男</span>
                </label>
                <label>
                  <input type='radio' name='PersonSex' value='女' id='Female' checked={gMemberInfo.sex === '女'} />
                  <span>女</span>
                </label>
                <label>
                  <input type='radio' name='PersonSex' value='女' id='NotCategorized' checked={gMemberInfo.sex === '不透漏'} />
                  <span>不透漏</span>
                </label>
              </div>
            </li>
            <li>
              <label>電子信箱</label>
              <div class='box'>
                <input type='email' value={gMemberInfo.email} />
              </div>
            </li>
            <li>
              <label>聯絡電話</label>
              <div class='box'>
                <input type='tel' value={gMemberInfo.phone} />
              </div>
            </li>
            <li>
              <label>年齡區間</label>
              <div class='box'>
                <select value={gMemberInfo.birthday}>
                  <option>20歲以下</option>
                  <option>21歲~30歲</option>
                  <option>31歲~40歲</option>
                  <option>41歲~50歲</option>
                  <option>51歲以上</option>
                </select>
              </div>
            </li>
            <li>
              <div class='button'>
                <input type='submit' value='取消修改' onClick={memberInfo} />
              </div>
            </li>
            {/* <li>
              <label>原密碼</label>
              <div class='box'>
                <input type='password' />
              </div>
            </li>
            <li>
              <label>新密碼</label>
              <div class='box'>
                <input type='password' />
              </div>
            </li>
            <li>
              <label>再次輸入新密碼</label>
              <div class='box'>
                <input type='password' />
              </div>
            </li> */}
          </ul>
        </div>

        <div class='content'>
          <ul class='PetLi'>
            <li>
              <div class='photo'>
                <img src='_img/pet/CatA.jpg' alt='寵物照片' />
              </div>
              <div class='name'>小黑(3歲)</div>
              <ul class='menu'>
                <li>
                  <Link to='/petinfo'>
                    <i class='material-icons'>visibility</i>
                  </Link>
                </li>
                <li>
                  <Link to='/petedit'>
                    <i class='material-icons'>edit</i>
                  </Link>
                </li>
                <li>
                  <Link to='/share'>
                    <i class='material-icons'>share</i>
                  </Link>
                </li>
                <li>
                  <Link to='/delete'>
                    <i class='material-icons'>delete</i>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div class='AddPetInfo button'>
            <input type='submit' value='＋ 新增寵物' />
          </div>
        </div>
      </section>
    </div>
  );
}

export default MemberEdit;
