import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import Context from '../store/context';
function Header() {
  const [value, setValue] = useContext(Context);

  return (
    <header>
      <div class='MenuLogo'>
        <div class='Logo'>
          <Link to='/'>
            <img src='_img/logo.png' alt='NARL-PetCare品牌識別' />
          </Link>
        </div>
        <nav>
          <input type='checkbox' class='openSidebarMenu' id='openSidebarMenu' />
          <label for='openSidebarMenu' class='sidebarIconToggle'>
            <div class='spinner diagonal part-1'></div>
            <div class='spinner horizontal'></div>
            <div class='spinner diagonal part-2'></div>
          </label>
          <div id='sidebarMenu'>
            <ul class='sidebarMenuInner'>
              <li>{value.login && value.expert && <Link to='help'>用戶的Help</Link>}</li>
              <li>
                <Link to='qna'>醫說毛小孩</Link>
              </li>
              <li>{value.login && value.expert && <Link to='/expertinfo'>專家資訊</Link>}</li>
              <li>{value.login && !value.expert && <Link to='/memberinfo'>飼主資訊</Link>}</li>
              <li>{!value.login.login && <Link to='/login'>登入/註冊</Link>}</li>
              <li>
                {value.login && (
                  <Link
                    to='/'
                    onClick={() => {
                      sessionStorage.removeItem('token');
                      setValue({ login: false, name: '' });
                    }}
                  >
                    登出
                  </Link>
                )}
              </li>
            </ul>
          </div>
          <ul class='DesktopMenu'>
            {value.login && <div class='identity'>HI~ {value.name}您好</div>}
            <li>{value.login && value.expert && <Link to='help'>用戶的Help</Link>}</li>
            <li>
              <Link to='qna'>醫說毛小孩</Link>
            </li>
            <li>{value.login && value.expert && <Link to='/expertinfo'>專家資訊</Link>}</li>
            <li>{value.login && !value.expert && <Link to='/memberinfo'>飼主資訊</Link>}</li>
            <li>{!value.login && <Link to='/login'>登入</Link>}</li>
            <li>
              {value.login && (
                <Link
                  to='/'
                  onClick={() => {
                    sessionStorage.removeItem('token');
                    setValue({ login: false, name: '' });
                  }}
                >
                  登出
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
