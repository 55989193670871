function Help() {
  return (
    <div>
      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>最新Help列表</h3>
          </div>
          <ul class='FillIn NewHelpLi'>
            <li>
              <label>飼主姓名</label>
              <div class='AnswerBox'>賴小姐</div>
            </li>
            <li>
              <label>寵物名</label>
              <div class='AnswerBox'>皮皮(狗2歲)</div>
            </li>
            <li class='SingleColumn'>
              <label>2022/04/06詢問</label>
              <div class='AnswerBox'>最近皮皮的食慾似乎不佳，以往一天 可以吃三餐，現在醫參也吃不完，對遊戲也興致缺缺，請問我家寵物怎麼了？</div>
            </li>
            <div class='button'>
              <a href='#'>寵物詳細資料</a>
              <a href='#' class='now'>
                回應
              </a>
            </div>
          </ul>
          <ul class='FillIn NewHelpLi'>
            <li>
              <label>飼主姓名</label>
              <div class='AnswerBox'>賴小姐</div>
            </li>
            <li>
              <label>寵物名</label>
              <div class='AnswerBox'>皮皮(狗2歲)</div>
            </li>
            <li class='SingleColumn'>
              <label>2022/04/06詢問</label>
              <div class='AnswerBox'>最近皮皮的食慾似乎不佳，以往一天 可以吃三餐，現在醫參也吃不完，對遊戲也興致缺缺，請問我家寵物怎麼了？</div>
            </li>
            <div class='button'>
              <a href='#'>寵物詳細資料</a>
              <a href='#' class='now'>
                回應
              </a>
            </div>
          </ul>
        </div>

        <div class='content PetDailyLogInfo'>
          <div class='FormBox InfoLi'>
            <div class='HeadlineA'>
              <h3>飼主的詢問、回應</h3>
            </div>
          </div>
          <div class='MedicalRecords'>
            <div class='HeadlineB'>
              <h5>飼主/林靜宜&nbsp;&nbsp;&nbsp;&nbsp; 寵物/小黑(貓)</h5>
              <div class='button'>
                <input type='submit' value='寵物詳細資料' />
              </div>
            </div>
            <div class='txt'>身體健康檢查(有牙結石狀況，04/08需回診)</div>
            <div class='QTxt'>
              2022/05/26問
              <br />
              我家的貓咪今天檢查出有牙結石，且有出血的症狀，想請問我該怎麼辦？
              <div class='button'>
                <input type='submit' value='回應' />
              </div>
            </div>
          </div>
          <div class='MedicalRecords'>
            <div class='HeadlineB'>
              <h5>飼主/林靜宜&nbsp;&nbsp;&nbsp;&nbsp; 寵物/小黑(貓)</h5>
              <div class='button'>
                <input type='submit' value='寵物詳細資料' />
              </div>
            </div>
            <div class='txt'>身體健康檢查(有牙結石狀況，04/08需回診)</div>
            <div class='QTxt'>
              2022/05/26問
              <br />
              我家的貓咪今天檢查出有牙結石，且有出血的症狀，想請問我該怎麼辦？
            </div>
            <div class='ATxt'>
              2022/04/06黃慧璧醫生答：
              <br />
              當食物殘渣與唾液經年累月堆積在牙齒上，就會形成牙結石，讓牙齒呈現泛黃，並且會散發異味。 若牙結石非常嚴重，通常會建議進行洗牙清潔，定期的刷牙則是避免牙結石最有效的方式。
              <div class='button'>
                <input type='submit' value='修改' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Help;
