import { Link } from 'react-router-dom';

function Delete() {
  return (
    <div class="FloatWin">
    <div class="FormBox CircleDiA">
        <div class="HeadlineA">
            <h3>刪除寵物資訊</h3>
        </div>
        <div class="hint">
        	您確認要刪除「寵物名」的所有資訊？<br />一旦點擊後，您將無法看到所有有關「寵物名」的資訊且無法恢復喔！
        </div>
        {/* <ul class="FillIn">
            <li>
            	<label>帳號</label>
                <div class="box">
                	<input type="email" placeholder="請填入註冊時所使用的電子信箱" />
                </div>
				<div class="ps">
					請至您原先註冊的E-MAIL收取信件
				</div>
            </li>
            <li>
            	<label>密碼</label>
                <div class="box">
        	        <input type="password" />
                </div>
            </li>

        </ul> */}
        <div class="button">
            <input type="submit" value="確認刪除" />
        	<input type="button" value="取消" />
        </div>
    </div>
</div>
  );
}

export default Delete;
