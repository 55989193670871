import { Link } from 'react-router-dom';

function PetBatchFillin() {
  return (
    <div>
      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>毛小孩資訊</h3>
            <div class='button'>
              <input type='submit' value='修改內容' />
            </div>
          </div>
          <ul class='FillIn abreast'>
            <li>
              <label>寵物名</label>
              <div class='AnswerBox'>小黑</div>
            </li>
            <li>
              <label>晶片號碼</label>
              <div class='AnswerBox'>158097006745901</div>
            </li>
            <li>
              <label>物種</label>
              <div class='AnswerBox'>貓</div>
            </li>
            <li>
              <label>品種</label>
              <div class='AnswerBox'>米克斯</div>
            </li>
            <li>
              <label>毛色</label>
              <div class='AnswerBox'>黑</div>
            </li>
            <li>
              <label>血型</label>
              <div class='AnswerBox'>AB</div>
            </li>
            <li>
              <label>生理性別</label>
              <div class='AnswerBox'>母</div>
            </li>
            <li>
              <label>年齡</label>
              <div class='AnswerBox'>1歲</div>
            </li>
            <li>
              <label>何時相遇</label>
              <div class='AnswerBox'>領養(2021/05/12)</div>
            </li>
            <li>
              <label>是否節育</label>
              <div class='AnswerBox'>是(2022/04/12)</div>
            </li>
            <li class='SingleColumn'>
              <label>特徵</label>
              <div class='AnswerBox'>有剪耳</div>
            </li>
            <li class='SingleColumn'>
              <label>個性</label>
              <div class='AnswerBox'>活潑、調皮</div>
            </li>
          </ul>
          <ul class='FillIn'>
            <li>
              <label>疫苗紀錄</label>
              <div class='AnswerBox'>
                2021/12/12 第二劑 三合一疫苗
                <br />
                2021/09/12 第一劑 三合一疫苗
              </div>
            </li>
            <li>
              <label>2022/04/08就醫紀錄</label>
              <div class='AnswerBox'>治療牙結石</div>
              <div class='button'>
                <a href='#'>詳細</a>
              </div>
            </li>
            <li>
              <label>2022/04/06就醫紀錄</label>
              <div class='AnswerBox '>身體健康檢查</div>
              <div class='button'>
                <a href='#'>詳細</a>
              </div>
            </li>
          </ul>
        </div>

        <div class='content'>
          <div class='FormBox'>
            <ul class='FillIn'>
              <li class='hr'>
                <div class='box'>
                  <label>日期</label>
                  <input type='date' />
                </div>
                <div class='box'>
                  <label>
                    <span>體重</span>
                    <input type='text' placeholder='kg' />
                  </label>
                  <label>
                    <span>廁所次數</span>
                    <input type='text' placeholder='次數' />
                  </label>
                  <label>
                    <span>廁所平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                  <label>
                    <span>飼料平均</span>
                    <input type='text' placeholder='g' />
                  </label>
                  <label>
                    <span>吃飼料平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                </div>
              </li>
              <li class='hr'>
                <div class='box'>
                  <label>日期</label>
                  <input type='date' />
                </div>
                <div class='box'>
                  <label>
                    <span>體重</span>
                    <input type='text' placeholder='kg' />
                  </label>
                  <label>
                    <span>廁所次數</span>
                    <input type='text' placeholder='次數' />
                  </label>
                  <label>
                    <span>廁所平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                  <label>
                    <span>飼料平均</span>
                    <input type='text' placeholder='g' />
                  </label>
                  <label>
                    <span>吃飼料平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                </div>
              </li>
              <li class='hr'>
                <div class='box'>
                  <label>日期</label>
                  <input type='date' />
                </div>
                <div class='box'>
                  <label>
                    <span>體重</span>
                    <input type='text' placeholder='kg' />
                  </label>
                  <label>
                    <span>廁所次數</span>
                    <input type='text' placeholder='次數' />
                  </label>
                  <label>
                    <span>廁所平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                  <label>
                    <span>飼料平均</span>
                    <input type='text' placeholder='g' />
                  </label>
                  <label>
                    <span>吃飼料平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                </div>
              </li>
              <li class='hr'>
                <div class='box'>
                  <label>日期</label>
                  <input type='date' />
                </div>
                <div class='box'>
                  <label>
                    <span>體重</span>
                    <input type='text' placeholder='kg' />
                  </label>
                  <label>
                    <span>廁所次數</span>
                    <input type='text' placeholder='次數' />
                  </label>
                  <label>
                    <span>廁所平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                  <label>
                    <span>飼料平均</span>
                    <input type='text' placeholder='g' />
                  </label>
                  <label>
                    <span>吃飼料平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                </div>
              </li>
              <li class='hr'>
                <div class='box'>
                  <label>日期</label>
                  <input type='date' />
                </div>
                <div class='box'>
                  <label>
                    <span>體重</span>
                    <input type='text' placeholder='kg' />
                  </label>
                  <label>
                    <span>廁所次數</span>
                    <input type='text' placeholder='次數' />
                  </label>
                  <label>
                    <span>廁所平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                  <label>
                    <span>飼料平均</span>
                    <input type='text' placeholder='g' />
                  </label>
                  <label>
                    <span>吃飼料平均</span>
                    <input type='text' placeholder='sec' />
                  </label>
                </div>
              </li>
              <li class='hr'>
                <div class='box'>
                  <label>日期</label>
                  <input type='date' />
                </div>
                <div class='box'>
                  <label>
                    <span>體重</span>
                    <input type='text' placeholder='kg' />
                  </label>
                  <label>
                    <span>如廁次數</span>
                    <input type='text' placeholder='請填入數字' />
                  </label>
                  <label>
                    <span>如廁平均時長</span>
                    <input type='text' placeholder='min' />
                  </label>
                </div>
              </li>
              <li class='hr'>
                <div class='box'>
                  <label>日期</label>
                  <input type='date' />
                </div>
                <div class='box'>
                  <label>
                    <span>體重</span>
                    <input type='text' placeholder='kg' />
                  </label>
                  <label>
                    <span>如廁次數</span>
                    <input type='text' placeholder='請填入數字' />
                  </label>
                  <label>
                    <span>如廁平均時長</span>
                    <input type='text' placeholder='min' />
                  </label>
                </div>
              </li>
            </ul>
            <div class='button'>
              <input type='submit' value='+ 新增項目' />
              <input type='submit' value='完成編輯' />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PetBatchFillin;
