import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Context from '../store/context';
import { gMemberInfo } from '../data/memberData';
import { Link, useNavigate } from 'react-router-dom';

function MemberInfo() {
  let navigate = useNavigate();
  const [value, setValue] = useContext(Context);
  const [deletevisible, setdeleteVisible] = useState(false);
  const [id, setID] = useState("0");
  const [data, setData] = useState({
    name: '黃惠瑩',
    owner_id: '獸師執85024',
    sex: '女',
    email: 'silverboxjenny@gmailcom',
    phone: '0982573676',
    birthday: '1985-07-23',
  });

  const [myArray,setMyArray] = useState([]);

  function petCreate(){
    navigate('/petcreate');
  }

  function showDeleteDialog(id) {
    setID(id);
    setdeleteVisible(true);
  }

  function cancelDeleteDialog() {
    setdeleteVisible(false);
  }

  function deletePet() {
    const token = sessionStorage.getItem('token');
    const url = process.env.REACT_APP_URL + '/v1/pet/delete';
    const data = { id: id };
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        alert("寵物刪除成功！")
        getPetList();
        setdeleteVisible(false);
        console.log(response.data);
      })
      .catch((error) => {
        alert('寵物刪除失敗！');
        console.error(error);
      });
  }

  function getPetList(){
    const api = process.env.REACT_APP_URL + '/v1/user/petlist';
    const token = sessionStorage.getItem('token');
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(api, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(response.data);
        setMyArray(response.data);
        /* setData({
          name: response.data.name,
          owner_id: response.data.owner_id,
          sex: response.data.sex,
          email: response.data.email,
          phone: response.data.phone,
          birthday: response.data.birthday,
        });
        gMemberInfo.name = response.data.name;
        gMemberInfo.owner_id = response.data.owner_id;
        gMemberInfo.sex = response.data.sex;
        gMemberInfo.email = response.data.email;
        gMemberInfo.phone = response.data.phone;
        gMemberInfo.birthday = response.data.birthday;
        setValue({ login: value.login, name: response.data.name, expert: value.expert }); */
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }

  useEffect(() => {
    const api = process.env.REACT_APP_URL + '/v1/user/info';
    const token = sessionStorage.getItem('token');
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(api, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(response.data);
        setData({
          name: response.data.name,
          owner_id: response.data.owner_id,
          sex: response.data.sex,
          email: response.data.email,
          phone: response.data.phone,
          birthday: response.data.birthday,
        });
        gMemberInfo.name = response.data.name;
        gMemberInfo.owner_id = response.data.owner_id;
        gMemberInfo.sex = response.data.sex;
        gMemberInfo.email = response.data.email;
        gMemberInfo.phone = response.data.phone;
        gMemberInfo.birthday = response.data.birthday;
        setValue({ login: value.login, name: response.data.name, expert: value.expert });
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    getPetList();
    //fetchData();
  }, []);

  return (
    <div>
      {deletevisible && (
        <div class='FloatWin'>
          <div class='FormBox CircleDiA'>
            <div class='HeadlineA'>
              <h3>刪除寵物資訊</h3>
            </div>
            <div class='hint'>
              您確認要刪除「寵物名」的所有資訊？
              <br />
              一旦點擊後，您將無法看到所有有關「寵物名」的資訊且無法恢復喔！
            </div>
            <div class='button'>
              <input type='submit' value='確認刪除' onClick={deletePet} />
              <input type='button' value='取消' onClick={cancelDeleteDialog} />
            </div>
          </div>
        </div>
      )}

      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>飼主資訊</h3>
            {/* <div class='button'>
              <Link to='/memberedit'>修改內容</Link>
            </div> */}
          </div>
          <ul class='FillIn'>
            <li>
              <label>姓名</label>
              <div class='AnswerBox'>{data.name}</div>
            </li>
            {/* <li>
              <label>飼主證件號碼</label>
              <div class='AnswerBox'>{data.owner_id}</div>
            </li> */}
            <li>
              <label>性別</label>
              <div class='AnswerBox'>{data.sex}</div>
            </li>
            <li>
              <label>電子信箱</label>
              <div class='AnswerBox'>{data.email}</div>
            </li>
            <li>
              <label>聯絡電話</label>
              <div class='AnswerBox'>{data.phone}</div>
            </li>
            <li>
              <label>年齡區間</label>
              <div class='AnswerBox'>{data.birthday}</div>
            </li>
          </ul>
          {/* <div class='button'>
            <input type='submit' value='取消修改' onClick={memberInfo} />
          </div> */}
        </div>

        <div class='content'>
          <div class='AddPetInfo button'>
            <input type='submit' value='＋ 新增寵物' onClick={petCreate} />
          </div>

          <ul class='PetLi'>
            {myArray.map((items, i) => {
              return (
                <li key={i}>
                  <div class='photo'>
                    <img src={items.photo} alt='寵物照片' />
                  </div>
                  <div class='name'>
                    {items.name}
                  </div>
                  <ul class='menu'>
                    <li>
                      <Link to={`/petinfo/${items.id}`}>
                        <i class='material-icons'>visibility</i><br />資訊
                      </Link>
                    </li>
                    <li>
                      <Link to={`/petedit/${items.id}`}>
                        <i class='material-icons'>edit</i><br />編輯
                      </Link>
                    </li>
                    {/* <li>
                        <Link to='/share'>
                          <i class='material-icons'>share</i>
                        </Link>
                      </li> */}
                    <li>
                      <a href='#' onClick={() => showDeleteDialog(items.id)}>
                        <i class='material-icons'>delete</i><br />刪除
                      </a>
                    </li>
                  </ul>
                </li>
              );
            })}
            {/* <li>
              <div class='photo'>
                <img src='_img/pet/CatA.jpg' alt='寵物照片' />
              </div>
              <div class='name'>小黑(3歲)</div>
              <ul class='menu'>
                <li>
                  <Link to='/petinfo'>
                    <i class='material-icons'>visibility</i>
                  </Link>
                </li>
                <li>
                  <Link to='/petedit'>
                    <i class='material-icons'>edit</i>
                  </Link>
                </li>
                <li>
                  <Link to='/share'>
                    <i class='material-icons'>share</i>
                  </Link>
                </li>
                <li>
                  <Link to='/delete'>
                    <i class='material-icons'>delete</i>
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </section>
    </div>
  );
}

export default MemberInfo;
