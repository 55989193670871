let gMemberInfo = {
  name: '黃惠瑩',
  owner_id: '獸師執85024',
  sex: '女',
  email: 'silverboxjenny@gmailcom',
  phone: '0982573676',
  birthday: '1985-07-23',
};

export { gMemberInfo };
