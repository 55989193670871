let gPetInfo = {
  id: '0',
  user_id: '7acf5abc-105b-49c7-827b-1f96a3b9065c',
  name: "abc",
  chip_no: "123456",
  blood_type: "o",
  species: "貓",
  type: "abc",
  color: "黑",
  sex: "男",
  feature: "短毛",
  personality: "好動",
  birthday: "2020/1/1",
  age: "2",
  get_type: "收養",
  get_date: "2021/1/1",
  birth_control: "否",
  birth_control_date: "2020/1/1",
  photo: "adc",
};

export { gPetInfo };
