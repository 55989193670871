import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Context from '../store/context';

function Login() {
  const [, setValue] = useContext(Context);
  const [username, setUsername] = useState('');
  const [pw, setPW] = useState('');
  let navigate = useNavigate();
  function doLogin() {
    const data = { username: username, password: pw };
    axios
      .post(process.env.REACT_APP_URL + '/v1/user/login', data)
      .then(function(response) {
        sessionStorage.setItem('token', response.data.data.token);

        console.log(response.data.data);
        switch (response.data.data.role) {
          case '1':
            setValue({ login: true, name: '',expert: false });
            navigate('/memberinfo');
            break;
          case '2':
            setValue({ login: true, name: '', expert: true });
            navigate('/expertinfo');
            break;
        }
      })
      .catch(function(error) {
        alert('帳號或密碼錯誤！');
        console.log(error);
      });
  }

  return (
    <div class='FloatWin'>
      <div class='FormBox CircleDiA'>
        <div class='HeadlineA'>
          <h3>會員登入</h3>
         {/*  <div class='menu'>
            <Link to='/register'>註冊會員</Link>
            <Link to='/forget'>忘記密碼</Link>
          </div> */}
        </div>
        <ul class='FillIn'>
          <li>
            <label>帳號</label>
            <div class='box'>
              <input type='text' onChange={(event) => setUsername(event.target.value)} />
            </div>
          </li>
          <li>
            <label>密碼</label>
            <div class='box'>
              <input type='password' onChange={(event) => setPW(event.target.value)} />
            </div>
          </li>
        </ul>
        <div class='button'>
          <input
            type='submit'
            onClick={() => {
              doLogin();
            }}
            value='登入'
          />
          <Link to='/'>取消</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
