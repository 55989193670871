import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { gPetInfo } from '../data/petData';

const initialValues = {
  id: 542845,
  rec_date: '2022-12-15',
  weight: 0,
  wc_count: 0,
  wc_avg: 0,
  food: 0,
  eat_avg: 0,
};


function PetFillin() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = useState(initialValues);
  function goback(){
    navigate(-1);
  }
  function createData() {
    const token = sessionStorage.getItem('token');
    axios
      .post(
        process.env.REACT_APP_URL + '/v1/pet/data/create',
        JSON.stringify({id:id,rec_date:values.rec_date,weight:values.weight,wc_count:values.wc_count,wc_avg:values.wc_avg,food:values.food,eat_avg:values.eat_avg}),
        {
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function (response) {
        console.log(response.data.data);
        goback();
      })
      .catch(function (error) {
        alert('資料建立失敗');
        console.log(error);
      });
  }
  return (
    <div>
      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>毛小孩資訊</h3>
            {/* <div class='button'>
              <input type='submit' value='修改內容' />
            </div> */}
          </div>
          <ul class='FillIn abreast'>
            <li>
              <label>寵物名</label>
              <div class='AnswerBox'>{gPetInfo.name}</div>
            </li>
            <li>
              <label>晶片號碼</label>
              <div class='AnswerBox'>{gPetInfo.chip_no}</div>
            </li>
            <li>
              <label>物種</label>
              <div class='AnswerBox'>{gPetInfo.species}</div>
            </li>
            <li>
              <label>品種</label>
              <div class='AnswerBox'>{gPetInfo.type}</div>
            </li>
            <li>
              <label>毛色</label>
              <div class='AnswerBox'>{gPetInfo.color}</div>
            </li>
            <li>
              <label>血型</label>
              <div class='AnswerBox'>{gPetInfo.blood_type}</div>
            </li>
            <li>
              <label>生理性別</label>
              <div class='AnswerBox'>{gPetInfo.sex}</div>
            </li>
            <li>
              <label>年齡</label>
              <div class='AnswerBox'>{gPetInfo.age}歲</div>
            </li>
            <li>
              <label>何時相遇</label>
              <div class='AnswerBox'>{gPetInfo.get_type}({gPetInfo.get_date})</div>
            </li>
            <li>
              <label>是否節育</label>
              <div class='AnswerBox'>{gPetInfo.birth_control}({gPetInfo.birth_control_date})</div>
            </li>
            <li class='SingleColumn'>
              <label>特徵</label>
              <div class='AnswerBox'>{gPetInfo.feature}</div>
            </li>
            <li class='SingleColumn'>
              <label>個性</label>
              <div class='AnswerBox'>{gPetInfo.personality}</div>
            </li>
          </ul>
          <ul class='FillIn'>
            {/* <li>
              <label>疫苗紀錄</label>
              <div class='AnswerBox'>
                2021/12/12 第二劑 三合一疫苗
                <br />
                2021/09/12 第一劑 三合一疫苗
              </div>
            </li> */}
            <li>
              <label>2022/04/08就醫紀錄</label>
              <div class='AnswerBox'>治療牙結石</div>
              <div class='button'>
                <a href='#'>詳細</a>
              </div>
            </li>
            <li>
              <label>2022/04/06就醫紀錄</label>
              <div class='AnswerBox '>身體健康檢查</div>
              <div class='button'>
                <a href='#'>詳細</a>
              </div>
            </li>
          </ul>
        </div>

        <div class='content'>
          <div class='FormBox'>
            <ul class='FillIn'>
              <li>
                <label>日期</label>
                <div class='box'>
                  <input
                    type='date'
                    onChange={(event) =>
                      setValues({
                        ...values,
                        rec_date: event.target.value,
                      })
                    }
                  />
                </div>
              </li>
              <li>
                <label>體重</label>
                <div class='box'>
                  <label>
                    <input
                      type='number'
                      value={values.weight}
                      onChange={(event) =>
                        setValues({
                          ...values,
                          weight: event.target.value,
                        })
                      }
                    />
                    &nbsp;公斤
                  </label>
                </div>
              </li>
              <li>
                <label>廁所次數</label>
                <div class='box'>
                  <label>
                    <input
                      type='number'
                      value={values.wc_count}
                      onChange={(event) =>
                        setValues({
                          ...values,
                          wc_count: event.target.value,
                        })
                      }
                    />
                    &nbsp;次
                  </label>
                </div>
              </li>
              <li>
                <label>廁所平均時間</label>
                <div class='box'>
                  <label>
                    <input
                      type='number'
                      value={values.wc_avg}
                      onChange={(event) =>
                        setValues({
                          ...values,
                          wc_avg: event.target.value,
                        })
                      }
                    />
                    &nbsp;秒
                  </label>
                </div>
              </li>
              <li>
                <label>飼料平均重量</label>
                <div class='box'>
                  <label>
                    <input
                      type='number'
                      value={values.food}
                      onChange={(event) =>
                        setValues({
                          ...values,
                          food: event.target.value,
                        })
                      }
                    />
                    &nbsp;公克
                  </label>
                </div>
              </li>
              <li>
                <label>吃飼料平均時間</label>
                <div class='box'>
                  <label>
                    <input
                      type='number'
                      value={values.eat_avg}
                      onChange={(event) =>
                        setValues({
                          ...values,
                          eat_avg: event.target.value,
                        })
                      }
                    />
                    &nbsp;秒
                  </label>
                </div>
              </li>
              {/* <li>
            	<label>如廁時長</label>
            	<div class="box">
					<label>
						<input type="text" placeholder="請填入數字" />&nbsp;min
					</label>
                </div>
				<div class="box">
					<label>10:00</label>
					<label>1min</label>
					<input type="submit" value="新增" />
                </div>
            </li> */}
              {/* <li>
            	<label>飲水狀況</label>
				<div class="box">
					<label>
						<input type="radio" name="Drinking" value="正常" id="WaterMore" />
                	    <span>正常</span>
					</label>
					<label>
						<input type="radio" name="DrinkingWater" value="較少" id="WaterLess" />
                	    <span>較少</span>
					</label>
					<label>
						<input type="text" placeholder="喝水量" />&nbsp;cc
					</label>
                </div>
				<div class="box">
					<label>8:00</label>
					<label>600cc</label>
					<input type="submit" value="新增" />
                </div>
            </li> */}
              {/*  <li>
            	<label>散步(可前往google fit、IOS健康找尋資料)</label>
				<div class="box">
					<label>
						<input type="text" placeholder="請填入公里數" />&nbsp;km
					</label>
					<label>
						<input type="text" placeholder="請填入時間" />&nbsp;min
					</label>
					<input type="submit" value="開始散步" />
                </div>
				<div class="box">
					<label>12:00</label>
					<label>0.5km</label>
					<label>30min</label>
					<input type="submit" value="新增" />
                </div>
            </li> */}
              {/* <li>
            	<label>飲食</label>
				<div class="box">
					<label>
						<input type="radio" name="PetDiet" value="飼料" id="feed" />
                	    <span>飼料</span>
					</label>
					<label>
						<input type="radio" name="PetDiet" value="自製" id="HomemadeFeed" />
                	    <span>自製</span>
					</label>
					<label>
						<input type="radio" name="PetDiet" value="混合" id="MixedDiet" />
                	    <span>混合</span>
					</label>
					<input type="text" placeholder="可備註食材、品牌" />
                </div>
            </li>
            <li>
            	<label>是否過敏</label>
				<div class="box">
					<label>
						<input type="radio" name="AllergyYN" value="否" id="AllergyN" />
                	    <span>否</span>
					</label>
					<label>
						<input type="radio" name="AllergyYN" value="是" id="AllergyY" />
                	    <span>是</span>
					</label>
					<input type="text" placeholder="過敏原" />
                </div>
            </li> */}
              {/* <li>
            	<label>是否施打疫苗</label>
				<div class="box">
					<label>
						<input type="radio" name="VaccineYN" value="無" id="VaccineN" />
                	    <span>無</span>
					</label>
					<label>
						<input type="radio" name="VaccineYN" value="有" id="VaccineY" />
                	    <span>有</span>
					</label>
					<input type="date" />
					<input type="text" placeholder="甚麼疫苗" />
                </div>
            </li> */}
              {/*  <li>
            	<label>就醫紀錄</label>
				<div class="box">
					<label>
					  <input type="radio" name="SeekMedicalCareYN" value="無" id="SeekMedicalCareN" />
                	    <span>無</span>
					</label>
					<label>
					  <input type="radio" name="SeekMedicalCareYN" value="有" id="SeekMedicalCareY" />
                	    <span>有</span>
					</label>
					<input type="date" />
                </div>
				<div class="box">
					<textarea placeholder="就醫狀況記錄"></textarea>
				</div>
            </li> */}
            </ul>
            <div class='button'>
              <input type='button' value='回列表' onClick={goback} />
              <input
                type='submit'
                value='確認'
                onClick={() => {
                  createData();
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PetFillin;
