import { Link,useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import Context from '../store/context';
import "chart.js/auto"
import { Chart } from 'react-chartjs-2';
import { gPetInfo } from '../data/petData';
import { Bar } from 'react-chartjs-2';
import ReactDOM from 'react-dom';

/* const chartData1 = {
  labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
  datasets: [
    {
      type: 'bar',
      label: '體重 kg',
      backgroundColor: 'rgba(245, 176, 65  ,0.9)',
      hoverBackgroundColor: 'rgba(243, 156, 18,1)',
      data: [17.2, 18.1, 18.2, 19.2, 20.1, 19.5, 18.2, 17.2, 17.4, 17.5, 17.2, 18.1, 18.3, 17.9, 18.5],
    },
  ],
}; */



/* const chartData2 = {
  labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
  datasets: [
    {
      type: 'line',
      label: '廁所次數',
      backgroundColor: 'rgba(52, 152, 219  ,0.9)',
      borderColor: 'rgba(52, 152, 219 ,0.9)',
      hoverBackgroundColor: 'rgba(46, 134, 193,1)',
      data: [5, 3, 7, 8, 5, 6, 8, 4, 2, 4, 3, 6, 8, 7, 5],
      yAxisID: 'y',
    },
    {
      type: 'bar',
      label: '廁所平均時長 sec',
      backgroundColor: 'rgba(236, 112, 99  ,0.7)',
      hoverBackgroundColor: 'rgba(231, 76, 60,1)',
      data: [55, 67, 72, 88, 52, 66, 98, 74, 82, 44, 30, 56, 48, 77, 65],
      yAxisID: 'y1',
    },
  ],
};

const options2 = {
  scales: {
    y: {
      beginAtZero: true,
      position: 'right',
      max: 14,
    },
    y1: {
      beginAtZero: true,
      position: 'left',
      max: 120,
    },
  },
}; */

/* const chartData3 = {
  labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
  datasets: [
    {
      type: 'line',
      label: '平均吃飼料時長 sec',
      backgroundColor: 'rgba(52, 152, 219  ,0.9)',
      borderColor: 'rgba(52, 152, 219  ,0.9)',
      hoverBackgroundColor: 'rgba(46, 134, 193,1)',
      data: [54, 35, 73, 82, 53, 63, 82, 43, 42, 64, 53, 76, 88, 67, 55],
      yAxisID: 'y',
    },
    {
      label: '飼料重量 g',
      backgroundColor: 'rgba(236, 112, 99  ,0.7)',
      hoverBackgroundColor: 'rgba(231, 76, 60,1)',
      data: [300, 380, 400, 340, 450, 550, 300, 280, 250, 500, 330, 400, 440, 290, 420],
      yAxisID: 'y1',
    },
  ],
};

const options3 = {
  scales: {
    y: {
      beginAtZero: true,
      position: 'right',
      max: 120,
    },
    y1: {
      beginAtZero: true,
      position: 'left',
      max: 600,
    },
  },
}; */



function PetInfo() {
  const { id } = useParams();
  const [value, setValue] = useContext(Context);
  const [data, setData] = useState({});
  const [myArray, setMyArray] = useState([]);
  const [visible, setVisible] = useState(false);
  const [chartvisible, setChartVisible] = useState(false);
  const [file, setFile] = useState();
  const [monthcount, setMonth] = useState(0);
  const [chartdata,setChartData] = useState({});

  const month = ['2022/6', '2022/7', '2022/8', '2022/9', '2022/10', '2022/11', '2022/12', '2023/1', '2023/2', '2023/3', '2023/4', '2023/5', '2023/6'];

  const chartData1 = {
    labels: chartdata.date,
    datasets: [
      {
        type: 'bar',
        label: '體重 kg',
        backgroundColor: 'rgba(245, 176, 65  ,0.9)',
        hoverBackgroundColor: 'rgba(243, 156, 18,1)',
        data: chartdata.weight,
        xAxesID: 'x',
        yAxisID: 'y',
      },
    ],
  };

  const options1 = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        max: Math.max.apply(this, chartdata.weight) + 1,
      },
    },
  };

  const chartData2 = {
    labels: chartdata.date,
    datasets: [
      {
        type: 'line',
        label: '廁所次數',
        backgroundColor: 'rgba(52, 152, 219  ,0.9)',
        borderColor: 'rgba(52, 152, 219 ,0.9)',
        hoverBackgroundColor: 'rgba(46, 134, 193,1)',
        data: chartdata.wc_count,
        xAxesID: 'x',
        yAxisID: 'y',
      },
      {
        type: 'bar',
        label: '廁所平均時長 sec',
        backgroundColor: 'rgba(236, 112, 99  ,0.7)',
        hoverBackgroundColor: 'rgba(231, 76, 60,1)',
        data: chartdata.wc_avg,
        xAxesID: 'x',
        yAxisID: 'y1',
      },
    ],
  };

  const options2 = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        position: 'right',
        max: Math.max.apply(this, chartdata.wc_count) + 3,
      },
      y1: {
        beginAtZero: true,
        position: 'left',
        max: Math.max.apply(this, chartdata.wc_avg) + 5,
      },
    },
  };

  const chartData3 = {
    labels: chartdata.date,
    datasets: [
      {
        type: 'line',
        label: '平均吃飼料時長 sec',
        backgroundColor: 'rgba(52, 152, 219  ,0.9)',
        borderColor: 'rgba(52, 152, 219  ,0.9)',
        hoverBackgroundColor: 'rgba(46, 134, 193,1)',
        data: chartdata.eat_avg,
        xAxesID: 'x',
        yAxisID: 'y',
      },
      {
        label: '飼料重量 g',
        backgroundColor: 'rgba(236, 112, 99  ,0.7)',
        hoverBackgroundColor: 'rgba(231, 76, 60,1)',
        data: chartdata.food,
        xAxesID: 'x',
        yAxisID: 'y1',
      },
    ],
  };

  const options3 = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        position: 'right',
        max: Math.max.apply(this, chartdata.eat_avg) + 5,
      },
      y1: {
        beginAtZero: true,
        position: 'left',
        max: Math.max.apply(this, chartdata.food) + 5,
      },
    },
  };

  let navigate = useNavigate();
  function toPetList() {
    navigate('/memberinfo');
  }
  function batchFillin() {
    navigate('/petbatchfillin');
  }
  function fillin() {
    navigate('/petfillin/' + id);
  }
  function uploadFile() {
    setVisible(true);
  }
  function cancel() {
    setVisible(false);
  }
  function showChart() {
    setChartVisible(true);
  }
  function showList() {
    setChartVisible(false);
  }
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  function addMonth(){
    if (monthcount<month.length-1) {
      updateChart(monthcount + 1);
      setMonth(monthcount + 1);
    }
  }
  function minusMonth() {
    if (monthcount>0){
      updateChart(monthcount - 1);
      setMonth(monthcount - 1);
    }
  }
  function updateChart(count){
    const token = sessionStorage.getItem('token');
    const url = process.env.REACT_APP_URL + '/v1/pet/chartdata';
    const data = { id: id, date: month[count] };
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    };
    axios
      .post(url, data, config)
      .then((response) => {
        console.log(response.data);
        setChartData({
          date: response.data.data.date_data,
          weight: response.data.data.weight_data,
          wc_count: response.data.data.wc_count_data,
          wc_avg: response.data.data.wc_avg_data,
          food: response.data.data.food_data,
          eat_avg: response.data.data.eat_avg_data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const token = sessionStorage.getItem('token');
    const url = process.env.REACT_APP_URL + '/v1/pet/data/upload';
    const current = new Date();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', 'pet' + id + '-' + `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}-${current.getHours()}-${current.getMinutes()}-${current.getSeconds()}` + '.xlsx');
    formData.append('petid',id);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token,
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
      alert('上傳資料成功');
      cancel();
    })
    .catch((error) => { console.error(error) })
  }
  useEffect(() => {
    const petid = sessionStorage.getItem('petid');
    const api = process.env.REACT_APP_URL + '/v1/pet/info?id=' + id;
    const token = sessionStorage.getItem('token');
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(api, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        console.log(response.data);
        setData({
          id: response.data.id,
          user_id: response.data.user_id,
          name: response.data.name,
          chip_no: response.data.chip_no,
          blood_type: response.data.blood_type,
          species: response.data.species,
          type: response.data.type,
          color: response.data.color,
          sex: response.data.sex,
          feature: response.data.feature,
          personality: response.data.personality,
          birthday: response.data.birthday,
          age: response.data.age,
          get_type: response.data.get_type,
          get_date: response.data.get_date,
          birth_control: response.data.birth_control,
          birth_control_date: response.data.birth_control_date,
          photo: response.data.photo,
        });
          gPetInfo.id= response.data.id
          gPetInfo.user_id= response.data.user_id
          gPetInfo.name= response.data.name
          gPetInfo.chip_no= response.data.chip_no
          gPetInfo.blood_type= response.data.blood_type
          gPetInfo.species= response.data.species
          gPetInfo.type= response.data.type
          gPetInfo.color= response.data.color
          gPetInfo.sex= response.data.sex
          gPetInfo.feature= response.data.feature
          gPetInfo.personality= response.data.personality
          gPetInfo.birthday= response.data.birthday
          gPetInfo.age= response.data.age
          gPetInfo.get_type= response.data.get_type
          gPetInfo.get_date= response.data.get_date
          gPetInfo.birth_control= response.data.birth_control
          gPetInfo.birth_control_date= response.data.birth_control_date
          gPetInfo.photo= response.data.photo
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    updateChart(monthcount);
  }, []);
  useEffect(() => {
    const petid = sessionStorage.getItem('petid');
    const api = process.env.REACT_APP_URL + '/v1/pet/data?id=' + id;
    const token = sessionStorage.getItem('token');
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(api, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setMyArray(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      {visible && (
        <div class='FloatWin'>
          <div class='FormBox CircleDiA'>
            <div class='HeadlineA'>
              <h3>上傳檔案</h3>
            </div>
            <ul class='FillIn'>
              <li>
                <form onSubmit={handleSubmit}>
                  <input name='file' type='file' onChange={handleChange} />
                  <br />
                  <br />
                  <input type='submit' value='上傳' style={{ height: '30px' }} />
                  <input type='submit' onClick={cancel} value='取消' style={{ height: '30px' }} />
                </form>
              </li>
            </ul>
          </div>
        </div>
      )}

      <section class='DivideA'>
        <div class='FormBox CircleDiA'>
          <div class='HeadlineA'>
            <h3>毛小孩資訊</h3>
            {/* <div class='button'>
              <input type='submit' value='內容存檔' />
            </div> */}
          </div>
          <ul class='FillIn abreast'>
            <li>
              <label>寵物名</label>
              <div class='AnswerBox'>{data.name}</div>
            </li>
            <li>
              <label>晶片號碼</label>
              <div class='AnswerBox'>{data.chip_no}</div>
            </li>
            {/* <li>
              <label>物種</label>
              <div class='AnswerBox'>{data.species}</div>
            </li> */}
            <li>
              <label>品種</label>
              <div class='AnswerBox'>{data.type}</div>
            </li>
            <li>
              <label>毛色</label>
              <div class='AnswerBox'>{data.color}</div>
            </li>
            <li>
              <label>血型</label>
              <div class='AnswerBox'>{data.blood_type}</div>
            </li>
            <li>
              <label>性別</label>
              <div class='AnswerBox'>{data.sex}</div>
            </li>
            {/*  <li>
              <label>年齡</label>
              <div class='AnswerBox'>{data.age}歲</div>
            </li> */}
            <li>
              <label>獲得方式</label>
              <div class='AnswerBox'>
                {data.get_type}
                {/* ({data.get_date}) */}
              </div>
            </li>
            <li>
              <label>是否節育</label>
              <div class='AnswerBox'>
                {data.birth_control}
                {/* ({data.birth_control_date}) */}
              </div>
            </li>
            <li class='SingleColumn'>
              <label>特徵</label>
              <div class='AnswerBox'>{data.feature}</div>
            </li>
            <li class='SingleColumn'>
              <label>個性</label>
              <div class='AnswerBox'>{data.personality}</div>
            </li>
          </ul>
          {/* <ul class='FillIn'>
            <li>
              <label>疫苗紀錄</label>
              <div class='AnswerBox'>
                2021/12/12 第二劑 三合一疫苗
                <br />
                2021/09/12 第一劑 三合一疫苗
              </div>
            </li>
            <li>
              <label>2022/04/08就醫紀錄</label>
              <div class='AnswerBox'>治療牙結石</div>
              <div class='button'>
                <a href='#'>詳細</a>
              </div>
            </li>
            <li>
              <label>2022/04/06就醫紀錄</label>
              <div class='AnswerBox '>身體健康檢查</div>
              <div class='button'>
                <a href='#'>詳細</a>
              </div>
            </li>
          </ul> */}
        </div>

        <div class='content'>
          <div class='AddPetInfo button'>
            <input type='submit' value='＋ 新增每日資訊' onClick={fillin} />
            {/* <input type='submit' value='＋ 批次新增資訊' onClick={batchFillin} /> */}
            <input type='submit' value='批次上傳 Excel、CSV檔案 ' onClick={uploadFile} />
            <input type='submit' value='返回寵物列表 ' onClick={toPetList} />
          </div>

          <ul class='PetDailyLogStyle'>
            <li>
              <a id='list' href='#' className={chartvisible ? '' : 'now'} onClick={showList}>
                <i class='material-icons'>list</i> 資料列表
              </a>
            </li>
            <li>
              <a id='chart' href='#' className={chartvisible ? 'now' : ''} onClick={showChart}>
                <i class='material-icons'>insights</i> 分析圖表
              </a>
            </li>
          </ul>

          {chartvisible && (
            <div class='ChartBox'>
              <div class='HeadlineC'>
                <a href='#' onClick={minusMonth}>
                  <i class='material-icons'>chevron_left</i>
                </a>
                <h3>{month[monthcount]}</h3>
                <a href='#' onClick={addMonth}>
                  <i class='material-icons'>chevron_right</i>
                </a>
              </div>
              <Chart type='bar' data={chartData1} options={options1} />
              <Chart type='bar' data={chartData2} options={options2} />
              <Chart type='bar' data={chartData3} options={options3} />
              <canvas id='myChart'></canvas>
              <canvas id='myChart2'></canvas>
              <canvas id='myChart3'></canvas>
            </div>
          )}
          {!chartvisible && (
            <ul class='PetDailyLogLi'>
              {myArray.map((items, i) => {
                return (
                  <li>
                    <div class='day'> {items.rec_date}</div>
                    <a href='#' class='DailyLog'>
                      <span class='weight'>
                        體重<b>{items.weight}</b>
                        <small>kg</small>
                      </span>
                      <span class='WCFrequency'>
                        廁所次數<b>{items.wc_count}</b>
                      </span>
                      <span class='WCTime'>
                        廁所平均<b>{items.wc_avg}</b>
                        <small>sec</small>
                      </span>
                      <span class='WCFrequency'>
                        飼料平均<b>{items.food}</b>
                        <small>g</small>
                      </span>
                      <span class='WCTime'>
                        吃飼料平均<b>{items.eat_avg}</b>
                        <small>sec</small>
                      </span>
                    </a>
                  </li>
                );
              })}
              {/* <li>
              <div class='day'>2022/04/11</div>
              <a href='#' class='DailyLog'>
                <span class='weight'>
                  體重<b>10.5</b>
                  <small>kg</small>
                </span>
                <span class='equal'>=</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li>
            <li>
              <div class='day'>2022/04/10</div>
              <a href='#' class='DailyLog'>
                <span class='time'>11:03</span>
                <span class='weight'>
                  體重<b>10.5</b>
                  <small>kg</small>
                </span>
                <span class='down'>▼</span>
                <span class='WCFrequency'>
                  如廁次數<b>3</b>
                </span>
                <span class='WCTime'>
                  如廁平均時長<b>4</b>
                  <small>min</small>
                </span>
                <i class='material-icons'>arrow_forward_ios</i>
              </a>
            </li> */}
            </ul>
          )}
        </div>
      </section>
    </div>
  );
}

export default PetInfo;
