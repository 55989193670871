function Home () {
  return (
    <div>
      <section>
        <div class='about'>
          <img src='_img/Index/web_a.jpg' alt='' />
        </div>
      </section>
    </div>
  );
}

export default Home;
