import './App.css';
import React, { useState } from 'react';
import Home from './pages/Home';
import Help from './pages/Help';
import Qna from './pages/Qna';
import MemberInfo from './pages/MemberInfo';
import MemberEdit from './pages/MemberEdit';
import PetCreate from './pages/PetCreate';
import PetInfo from './pages/PetInfo';
import PetEdit from './pages/PetEdit';
import PetBatchFillin from './pages/PetBatchFillin';
import PetFillin from './pages/PetFillin';
import ExpertInfo from './pages/ExpertInfo';
import ExpertEdit from './pages/ExpertEdit';
import Login from './components/login';
import Forget from './components/forget';
import Register from './components/register';
import Share from './components/share';
import Delete from './components/delete';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './layout/footer';
import Header from './layout/header';
import Context from './store/context';

function App() {
  const [value, setValue] = useState({login: false,name: '',expert: false});

  return (
    <BrowserRouter>
      <Context.Provider value={[value, setValue]}>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/help' element={<Help />} />
          <Route path='/qna' element={<Qna />} />
          <Route path='/memberinfo' element={<MemberInfo />} />
          <Route path='/memberedit' element={<MemberEdit />} />
          <Route path='/petcreate' element={<PetCreate />} />
          <Route path='/petinfo/:id' element={<PetInfo />} />
          <Route path='/petedit/:id' element={<PetEdit />} />
          <Route path='/petbatchfillin' element={<PetBatchFillin />} />
          <Route path='/petfillin/:id' element={<PetFillin />} />
          <Route path='/expertinfo' element={<ExpertInfo />} />
          <Route path='/expertedit' element={<ExpertEdit />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forget' element={<Forget />} />
          <Route path='/register' element={<Register />} />
          <Route path='/share' element={<Share />} />
          <Route path='/delete' element={<Delete />} />
        </Routes>
        <Footer />
      </Context.Provider>
    </BrowserRouter>
  );
}

export default App;
