function Footer() {
  return (
    <div>
      <footer>
        <ul>
          <li>Copyright © NARL-PetCare Industrial Co., Ltd.</li>
        </ul>
      </footer>
    </div>
  );
}

export default Footer;
