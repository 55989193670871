import { Link } from 'react-router-dom';

function Share() {
  return (
    <div class="FloatWin">
    <div class="CircleDiA">
        <div class="HeadlineA">
            <h3>分享連結</h3>
        </div>
		<div class="ResponsePage article">
            <i class="material-icons">content_copy</i>
            <p>
            點選複製即可分享「寵物名」的資訊
            <mark>http://www.narl-petcare.com/548656256465</mark>
            </p>
        <div class="button">
            <input type="submit" value="複製" />
        </div>
		</div>
    </div>
</div>
  );
}

export default Share;
